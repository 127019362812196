import {
	HANDLE_SPLASH_SCREEN,
	HANDLE_LOADING,
	MODAL_ACTION,
	LIGHTBOX_ACTION,
	DISABLE_FORM_BUTTON_ACTION,
	ADD_WRAPPER,
	ZOOM_BUTTON_LIGHTBOX_ACTION,
	FETCH_ROLES,
	DOCUMENT_PREVIEW,
	PROGRESS_ACTION,
	SET_PROGRESS_ACTION,
	NOTIFICATIONS_MODAL_ACTION,
	HANDLE_BLOCKING_UI,
} from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	splash_screen: true,
	loading: false,
	files: [],
	modal: {
		id: '',
		open: false,
		title: '',
		description: '',
		text: '',
		button_no: '',
		button_no_id: '',
		button_yes: '',
		disable_click_away: false,
		action_no_button: null,
		props_no_button: null,
		action_on_close: null,
		show_button_yes: true,
		show_button_no: true,
		button_yes_id: '',
		action: null,
		props: null,
		max_width: 'sm',
    input: false
	},
	notifications_modal: {
		open: false,
	},
	progress: {
		open: false,
		value: 0,
		title: '',
		description: '',
		text: '',
		button_cancel: '',
		button_cancel_id: '',
		action_cancel_button: null,
		props_cancel_button: null,
		props: null,
		canceled: false,
	},
	lightbox: {
		open: false,
		image: [],
		disabledZoomButton: false,
		screenshot: null,
	},
	form: {
		disable_button: false,
	},
	wrapper: 1,
	roles: [],
	document_preview: {
		loading: false,
	},
	blocking_ui: false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case HANDLE_SPLASH_SCREEN:
			return {
				...state,
				splash_screen: action.payload,
			};
		case HANDLE_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case MODAL_ACTION:
			return {
				...state,
				modal: { ...initialState.modal, ...action.payload },
			};
		case LIGHTBOX_ACTION:
			return {
				...state,
				lightbox: {
					open: action.payload.open,
					image: action.payload.image,
					disabledZoomButton: action.payload.disabledZoomButton,
					screenshot: action.payload.screenshot ? action.payload.screenshot : null,
				},
			};
		case ZOOM_BUTTON_LIGHTBOX_ACTION:
			return {
				...state,
				lightbox: {
					...state.lightbox,
					disabledZoomButton: action.payload,
				},
			};
		case DISABLE_FORM_BUTTON_ACTION:
			return {
				...state,
				form: {
					disable_button: action.payload,
				},
			};
		case HANDLE_BLOCKING_UI:
			return {
				...state,
				blocking_ui: action.payload
			};
		case ADD_WRAPPER:
			return {
				...state,
				wrapper: action.payload,
			};
		case FETCH_ROLES:
			return {
				...state,
				roles: { ...state.roles, [action.payload.type]: action.payload.data },
			};
		case DOCUMENT_PREVIEW:
			return {
				...state,
				document_preview: { ...state.document_preview, ...action.payload },
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
				...{ splash_screen: false },
			};
		case PROGRESS_ACTION:
			return {
				...state,
				progress: { ...initialState.progress, ...action.payload },
			};
		case SET_PROGRESS_ACTION:
			return {
				...state,
				progress: { ...state.progress, value: action.payload },
			};
		case NOTIFICATIONS_MODAL_ACTION:
			return {
				...state,
				notifications_modal: { ...initialState.notifications_modal, ...action.payload },
			};
		default:
			return state;
	}
}
