import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLinkTypeAction } from './actions/chatActions';
import {
	setChannelAction,
	setChannelHistoryAction,
	sendChannelMessageAction,
	clearChannelAction,
	setChannelParticipantsAction,
	setChannelNewMessagesAction,
	setReadTicketNotificationsAction,
} from './actions/websocketsActions';
import { fetchTicketFollowupQuestionnaireAction, fetchTicketAction } from '../Tickets/actions/ticketsActions';
import { parseDateUTC } from '../../helpers/functions/functions';
import soundfile from '../../assets/sounds/mention-alert.mp3';
import moment from 'moment';
import ErrorBoundary from '../Generic/ErrorBoundary';
import axios from 'axios';

export const SocketContext = React.createContext();

/** Connects to a websocket channel on ws://<host>/ws
 * Attempts to get a short lived token from the api to use as authentication
 * when connecting to the websocket channel and then proceeds try to connect.
 *
 * Tokens are very short lived so a new one should be fetched if you need to
 * reconnect.
 */
class SocketProvider extends Component {
	constructor() {
		super();
		this.state = {
			ws: null,
			token: null,
			attempts: 0,
		};

		this.audio = new Audio(soundfile);
	}

	componentDidMount() {
		this.preconnect();
	}

	componentWillUnmount() {
		if (this.state.ws !== null) {
			this.state.ws.close(4005);
		}
	}

	timeout = 250; // Initial timeout duration as a class variable

	preconnect = () => {
		if (!this.props.already_connected && this.state.attempts < 5) {
			axios
				.get(this.props.config.apihost + '/channels/' + this.props.channel + '/token')
				.then((res) => {
					this.setState(
						{
							token: res.data.token,
							attempts: this.state.attempts + 1,
						},
						() => {
							this.connect();
						}
					);
				})
				.catch((err) => {
					console.log('SocketProvider failed to connect to websocket channel');
				});
		}
	};

	/**
	 * @function connect
	 * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
	 */
	connect = () => {
		var ws = new WebSocket(
			this.props.config.websocket_protocol + `://` + this.props.config.websockethost + '/' + this.props.channel + '?token=' + this.state.token
		);

		let that = this; // cache the this
		var connectInterval;

		// websocket onopen event listener
		ws.onopen = () => {
			var connection_date = new Date();
			that.setState({
				connection_date,
				attempts: 0,
				ws: ws,
			});

			that.timeout = 250; // reset timer to 250 on open of websocket connection
			clearTimeout(connectInterval); // clear Interval on on open of websocket connection

			setTimeout(() => {
				this.sendListCommand(ws, connection_date);
			}, 1000);
		};

		// websocket onclose event listener
		ws.onclose = (e) => {
			this.props.clearChannelAction(this.props.channel_name);

			if (e.code !== 4005) {
				that.timeout = that.timeout + that.timeout; //increment retry interval
				connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
			}
		};

		// websocket onerror event listener
		ws.onerror = (err) => {
			ws.close();
		};

		ws.onmessage = (msg) => {
			var data = JSON.parse(msg.data);

			if (data.created) {
				data.created = moment(data.created).format('YYYY-MM-DD HH:mm:ss');
			}

			if (data.type === 'history') {
				if (data.message && data.message.length > 0) {
					data.message.forEach((message) => {
						if (message.created) {
							message.created = moment(message.created).format('YYYY-MM-DD HH:mm:ss');
						}
					});
				}
				if (this.props.handleFilterMessages && Array.isArray(data.message)) {
					data.message = data.message.filter(this.props.handleFilterMessages);
				}
				this.props.setChannelHistoryAction(this.props.channel_name, data.message);
			} else if (data.type === 'participants') {
				this.props.setChannelParticipantsAction(this.props.channel_name, data.message);
			} else {
				if (this.props.handleFilterMessages) {
					if (this.props.handleFilterMessages(data)) {
						this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
					}
					if (data.type === 'readmark' || data.type === 'downloadmark' ) {
						this.props.setReadTicketNotificationsAction(this.props.channel_name, data.origin_type, data.origin_id, data.ref_type, data.ref_id); 
					}
				} else {
					this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
					if (data.type === 'readmark' || data.type === 'downloadmark' ) {
						this.props.setReadTicketNotificationsAction(this.props.channel_name, data.origin_type, data.origin_id, data.ref_type, data.ref_id); 
					}
				}
				if (
					this.state.connection_date !== null &&
					data.automatic === '0' &&
					data.sender_id !== this.props.user.id &&
					typeof data.created !== 'undefined' &&
					parseDateUTC(data.created) > this.state.connection_date
				) {
					if (
						this.props.mentions &&
						data.users_mentions &&
						Array.isArray(data.users_mentions) &&
						data.users_mentions.includes(this.props.user.id)
					) {
						this.props.setChannelNewMessagesAction(this.props.channel_name, true);
						this.audio.play();
					} else {
						this.props.setChannelNewMessagesAction(this.props.channel_name);
					}
				}
				// if (data) {
				// 	data.message.includes(`<span id="chat-user-mention" data-id="${this.props.user.id}">`)
				// }
				if (this.props.handleSendMessage) this.props.handleSendMessage(data);
			}
		};
	};

	sendListCommand = (ws, connection_date) => {
		ws.send(JSON.stringify({ type: 'command', command: 'list' }));

		let arr = this.props.messages;

		/**
		 * FIXME: Add api fetch instead to get last 10 or so
		 */

		if (this.props.customFirstMessage) {
			let msg = this.props.customFirstMessage();
			arr.push(JSON.parse(msg));
		}

		this.props.setChannelAction(this.props.channel_name, this.props.channel, arr, connection_date);
	};

	/**
	 * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
	 */
	check = () => {
		const { ws } = this.state;
		if (!ws || ws.readyState === WebSocket.CLOSED) this.preconnect(); //check if websocket instance is closed, if so call `connect` function.
	};

	render() {
		return <SocketContext.Provider value={{ ws: this.state.ws }}>{this.props.children}</SocketContext.Provider>;
	}
}

const mapStateToProps = (state, ownProps) => ({
	user: state.users.whoami,
	messages: state.websockets[ownProps.channel_name].messages,
	config: state.config,
	timezone: state.users.whoami.timezone,
});

export default connect(mapStateToProps, {
	setChannelAction,
	setChannelHistoryAction,
	sendChannelMessageAction,
	clearChannelAction,
	fetchLinkTypeAction,
	fetchTicketFollowupQuestionnaireAction,
	fetchTicketAction,
	setChannelParticipantsAction,
	setChannelNewMessagesAction,
	setReadTicketNotificationsAction,
})(SocketProvider);

SocketProvider.propTypes = {
	channel_name: PropTypes.string.isRequired,
	channel: PropTypes.string.isRequired,
	handleSendMessage: PropTypes.func,
	customFirstMessage: PropTypes.func,
	handleFilterMessages: PropTypes.func,
};
