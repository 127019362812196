import React from 'react';
import { TextField, FormHelperText, MenuItem } from '@material-ui/core';

function RenderTextField({ input, meta: { invalid, touched, error }, ...custom }) {
	if (custom.select && custom.inputdata.disableEmptyOption) {
		return (
			<div>
				<TextField
					{...input}
					id={custom.inputdata.id}
					name={custom.inputdata.name}
					label={custom.inputdata.label}
					placeholder={custom.inputdata.placeholder}
					fullWidth
					variant="outlined"
					disabled={custom.inputdata.readonly}
					error={touched && invalid}
					helperText={touched && error}
					onChange={(event) => {
						input.onChange(event);
						if (typeof custom.inputdata.onChange !== 'undefined') {
							custom.inputdata.onChange(event.target.value);
						}
					}}
					type={custom.inputdata.type}
					size={custom.inputdata.size ? custom.inputdata.size : null}
					{...custom}
					inputProps={{
						step: custom.inputdata.props ? custom.inputdata.props.step : null,
						min: custom.inputdata.props ? custom.inputdata.props.min : null,
						maxLength: custom.inputdata.props ? custom.inputdata.props.maxLength : null,
						className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
					}}
				>
					{custom.inputdata.type === 'select' &&
						custom.inputdata.options &&
						custom.inputdata.options.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
				</TextField>
				<FormHelperText id="my-helper-text"> {custom.inputdata.helperText}</FormHelperText>
			</div>
		);
	} else {
		return (
			<div>
				<TextField
					{...input}
					id={custom.inputdata.id}
					name={custom.inputdata.name}
					label={custom.inputdata.label}
					placeholder={custom.inputdata.placeholder}
					fullWidth
					variant="outlined"
					disabled={custom.inputdata.readonly}
					error={touched && invalid}
					helperText={touched && error}
					onChange={(event) => {
						input.onChange(event);
						if (typeof custom.inputdata.onChange !== 'undefined') {
							custom.inputdata.onChange(event.target.value);
						}
					}}
					type={custom.inputdata.type}
					size={custom.inputdata.size ? custom.inputdata.size : null}
					{...custom}
					inputProps={{
						step: custom.inputdata.props ? custom.inputdata.props.step : null,
						min: custom.inputdata.props ? custom.inputdata.props.min : null,
						maxLength: custom.inputdata.props ? custom.inputdata.props.maxLength : null,
						className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
					}}
				/>
				<FormHelperText id="my-helper-text"> {custom.inputdata.helperText}</FormHelperText>
			</div>
		);
	}
}

export default RenderTextField;
